import React from 'react'
import { Svg, Circle } from 'react-native-svg'
import COLORS from '../../utils/colors'

export const RadioButtonUncheckedIcon = (props) => {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <Circle
                cx="10"
                cy="10"
                r="9"
                stroke={COLORS.COLOR_GRAY}
                strokeWidth="2"
                fill="none"
            />
        </Svg>
    )
}
