import React from 'react'

import { SmallMediumCardProps } from '../Card/interface'
import { Size } from '../../utils/constants'
import { SmallMediumCard } from '../Card/SmallMediumCard'

export interface MediumProductCardProps
    extends Omit<SmallMediumCardProps, 'image'> {
    productImage?: React.ReactNode
}
export const MediumProductCard = (props: MediumProductCardProps) => {
    const { productImage, ...rest } = props
    return <SmallMediumCard image={productImage} {...rest} size={Size.Medium} />
}
