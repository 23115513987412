import React from 'react'
import { Image } from 'react-native'

/**
 * @deprecated Since version 1.1.9 Will be fixed in newer releases
 */
const BasicAreaChart = () => {
    return (
        <Image
            source={{ uri: '/basic_area_chart_desktop_.png' }}
            style={{ height: 250, width: 500 }}
        />
    )
}

export default BasicAreaChart
