import React from 'react'
import { Text, View } from 'react-native'
import { styles } from './styles'
import { Size } from '../../utils/constants'
import { CardLoading } from './CardLoading'
import { CardTitle } from './CardTitle'
import { SmallMediumCardBase } from './SmallMediumCardBase'
import { SmallMediumCardProps } from './interface'

export const SmallMediumCard: React.FC<
    SmallMediumCardProps & { size?: Size; type?: string }
> = (props) => {
    const {
        containerStyle,
        loading,
        topIcon,
        topIconPosition,
        image,
        titleTextStyle,
        title,
        descriptionTextStyle,
        description,
        price,
        priceTextStyle,
        pressableProps,
        size,
        testID,
    } = props

    const isSmallCard = size === Size.Small
    const isMediumCard = size === Size.Medium

    if (loading) {
        return (
            <CardLoading
                testID={`${testID}-loading`}
                size={size}
                topIconPosition={topIconPosition}
                containerStyle={containerStyle}
            />
        )
    }

    const RenderCardTitle = () => (
        <CardTitle
            title={title}
            titleTextStyle={titleTextStyle}
            description={description}
            descriptionTextStyle={descriptionTextStyle}
            {...pressableProps}
        />
    )

    return (
        <>
            <View
                testID={`${testID}-container`}
                style={[styles.containerStyle, containerStyle]}
            >
                <SmallMediumCardBase
                    topIcon={topIcon}
                    topIconPosition={topIconPosition}
                    image={image}
                    testID={`${testID}-card-base`}
                >
                    {isSmallCard && <RenderCardTitle />}
                </SmallMediumCardBase>

                {isMediumCard && (
                    <>
                        <RenderCardTitle />
                        <Text style={[styles.priceTextStyle, priceTextStyle]}>
                            {price}
                        </Text>
                    </>
                )}
            </View>
        </>
    )
}
