import React from 'react'
import { View } from 'react-native'
import { StepData, StepsProps } from './interface'
import { styles } from './styles'
import Step from './Step'

const Steps: React.FC<StepsProps> = (props) => {
    const {
        data,
        parentContainer,
        showSeparator,
        lineSeparatorStyle,
        layout = 'horizontal',
        separatorComponent,
    } = props

    return (
        <View
            style={[
                layout === 'horizontal'
                    ? styles.parentContainer
                    : styles.parentContainerVertical,
                parentContainer,
            ]}
        >
            {data?.map((item: StepData, index: number) => (
                <React.Fragment key={index}>
                    <Step icon={item.icon} {...item} />
                    {showSeparator &&
                        index !== data.length - 1 &&
                        (separatorComponent ? (
                            separatorComponent
                        ) : (
                            <View
                                style={[styles.separator, lineSeparatorStyle]}
                            />
                        ))}
                </React.Fragment>
            ))}
        </View>
    )
}

export default Steps
