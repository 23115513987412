//@ts-nocheck
import React, { Component } from 'react'
import ProgressIndicatorCircular from './component/progressIndicatorCircluar'
import ProgressIndicatorBar from './component/progressIndicatorBar'
import {
    ProgressIndicatorBarProps,
    ProgressIndicatorCircularProps,
    BaseProgressIndicatorProps,
} from './interface'


export type ProgressIndicatorProps = BaseProgressIndicatorProps & (ProgressIndicatorCircularProps | ProgressIndicatorBarProps);

const ProgressIndicator = (props: ProgressIndicatorProps) => {
    const { progressIndicatorCircular, ...otherProps } = props;

    return (
        <>
            {progressIndicatorCircular ? (
                <ProgressIndicatorCircular {...otherProps} />
            ) : (
                <ProgressIndicatorBar {...otherProps} />
            )}
        </>
    );
};


export default ProgressIndicator
