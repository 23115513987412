import React, { Component } from 'react'
import { View, Pressable, FlatList, LogBox, Image } from 'react-native'

import { IAccordion, IAccordionItem, IAccordionItemState } from './interface'
import { styles } from './styles'
import { GestureResponderEvent } from 'react-native-modal'

import ArrowUp from '../../assets/images/image-expand-more-24px-up.png'
import ArrowDown from '../../assets/images/image-expand-more-24px-down.png'
import { Typography } from '../Typography/Typography'

export class Accordion extends Component<IAccordion, { selectedId?: string }> {
    constructor(props: IAccordion) {
        super(props)
        this.state = { selectedId: undefined }
    }

    componentDidMount() {
        // ignore warning for nested virtualized lists when the component used inside ScrollView
        LogBox.ignoreLogs(['VirtualizedLists should never be nested'])
    }

    render() {
        const {
            data,
            containerStyle,
            topArrow,
            bottomArrow,
            titleStyle,
            subTitleStyle,
            itemContainerStyle,
            titleContainerStyle,
            selectedId,
            handleChange,
        } = this.props

        const _topArrow =
            topArrow === undefined ? (
                <Image source={ArrowUp} style={styles.iconStyle} />
            ) : (
                topArrow
            )

        const _bottomArrow =
            bottomArrow === undefined ? (
                <Image source={ArrowDown} style={styles.iconStyle} />
            ) : (
                bottomArrow
            )

        return (
            <FlatList
                style={[styles.containerStyle, containerStyle]}
                data={data}
                keyExtractor={(item) => item.id}
                renderItem={({ item, index }) => {
                    return (
                        <RenderAccordionItem
                            item={item}
                            index={index}
                            topArrow={_topArrow}
                            bottomArrow={_bottomArrow}
                            titleStyle={titleStyle}
                            subTitleStyle={subTitleStyle}
                            style={itemContainerStyle}
                            // controlled component passes boolean value if 'handleChange' exists
                            selected={
                                handleChange
                                    ? selectedId === item.id
                                    : undefined
                            }
                            handleChange={handleChange}
                            titleContainerStyle={titleContainerStyle}
                        />
                    )
                }}
            />
        )
    }
}

export class RenderAccordionItem extends Component<
    IAccordionItem,
    IAccordionItemState
> {
    constructor(props: IAccordionItem) {
        super(props)
        this.state = {
            // always closed in uncontrolled state
            isVisible: !!props.selected,
        }
    }

    RenderAccordionHeader = ({
        item,
        topArrow,
        bottomArrow,
        titleContainerStyle,
        titleStyle,
        subTitleStyle,
        onSelect,
        isVisible,
    }: any) => {
        return (
            <Pressable
                onPress={(e) => onSelect(item.id, e)}
                style={[styles.headerContainer, titleContainerStyle]}
            >
                <View style={styles.titleWithIcon}>
                    <Typography style={{ ...titleStyle }}>
                        {item.title}
                    </Typography>
                    {isVisible ? topArrow : bottomArrow}
                </View>
                {item.subTitle ? (
                    <Typography
                        style={{ ...styles.subTitle, ...subTitleStyle }}
                    >
                        {item.subTitle}
                    </Typography>
                ) : null}
            </Pressable>
        )
    }

    render() {
        const {
            item,
            topArrow,
            bottomArrow,
            style,
            selected,
            titleStyle,
            titleContainerStyle,
            subTitleStyle,
            handleChange,
        } = this.props

        const { isVisible } = this.state
        const Content = item.content

        const handleSelect = (id: string, event: GestureResponderEvent) => {
            if (selected === undefined) {
                this.setState({ isVisible: !this.state.isVisible })
            } else {
                // controlled component
                handleChange && handleChange(id, event)
            }
        }

        const isItemSelected = selected === undefined ? isVisible : selected

        return (
            <View style={[styles.itemContainer, style]}>
                <this.RenderAccordionHeader
                    item={item}
                    topArrow={topArrow}
                    bottomArrow={bottomArrow}
                    titleContainerStyle={titleContainerStyle}
                    titleStyle={titleStyle}
                    subTitleStyle={subTitleStyle}
                    onSelect={handleSelect}
                    isVisible={isItemSelected}
                />
                {isItemSelected ? Content : null}
            </View>
        )
    }
}
