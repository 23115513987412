import React from 'react'
import { Svg, Circle } from 'react-native-svg'
import COLORS from '../../utils/colors'

export const RadioButtonCheckedIcon = (props) => {
    return (
        <Svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <Circle
                cx="10"
                cy="10"
                r="9"
                stroke={COLORS.COLOR_MEDIUMBLUE}
                strokeWidth="1"
                fill={COLORS.COLOR_MEDIUMBLUE}
            />
            <Circle cx="10" cy="10" r="4" fill="white" />
        </Svg>
    )
}
