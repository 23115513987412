import React from 'react'

import { Size } from '../../utils/constants'
import { SmallMediumCardProps } from '../Card/interface'
import { SmallMediumCard } from '../Card/SmallMediumCard'

export interface SmallProductCardProps
    extends Omit<SmallMediumCardProps, 'image'> {
    productImage?: React.ReactNode
}

export const SmallProductCard = (props: SmallProductCardProps) => {
    const { productImage, ...rest } = props
    return <SmallMediumCard image={productImage} {...rest} size={Size.Small} />
}
