import React from 'react'

import { WideCard, WideCardProps } from '../WideCard/WideCard'

export interface WideProductCardProps extends WideCardProps {}

export const WideProductCard: React.FC<WideProductCardProps & {}> = (props) => {
    return (
        <WideCard {...props} />
    )
}
