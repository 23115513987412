import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import { RadioButtonCheckedIcon } from './RadioButtonCheckedIcon'
import { RadioButtonUncheckedIcon } from './RadioButtonUncheckedIcon'
import { CheckBox, CheckBoxProps } from '@rneui/themed'
import { CheckBoxIconProps } from '@rneui/base/dist/CheckBox/components/CheckBoxIcon'

export type RadioButtonProps = Omit<CheckBoxProps, 'checked'> &
    Omit<CheckBoxIconProps, 'checked'> & {
        children?: undefined
        checked?: boolean
        customCheckedIcon?: React.ReactNode
        customUncheckedIcon?: React.ReactNode
        checkedIconTestID?: string
        unCheckedIconTestID?: string
    }

export const RadioButton: React.FC<RadioButtonProps> = (
    props: RadioButtonProps,
) => {
    const {
        checked,
        title,
        customCheckedIcon,
        customUncheckedIcon,
        checkedIconTestID,
        unCheckedIconTestID,
        ...rest
    } = props
    const [isChecked, setIsChecked] = useState(checked)

    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <CheckBox
            title={title}
            checked={isChecked}
            onPress={() => {
                setIsChecked(!isChecked)
            }}
            checkedIcon={
                customCheckedIcon ? (
                    <View>{customCheckedIcon}</View>
                ) : (
                    <RadioButtonCheckedIcon testID={checkedIconTestID} />
                )
            }
            uncheckedIcon={
                customUncheckedIcon ? (
                    <View>{customUncheckedIcon}</View>
                ) : (
                    <RadioButtonUncheckedIcon testID={unCheckedIconTestID} />
                )
            }
            {...rest}
        />
    )
}
