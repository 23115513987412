import React from 'react'
import { View, Pressable, Text, StyleProp, TextStyle } from 'react-native'

import COLORS from '../../utils/colors'
import { ICalendar, ICalendarState } from './interface'
import styles from './styles'
import Calendar from './Calendar'
import OutsideClickHandler from '../../molecules/OutsideClickHandler'

export type CalendarInputProps = ICalendar & {
    leftIcon?: React.ReactNode
    placeHolder?: string
    placeHolderStyle?: StyleProp<TextStyle>
    popUpWidth?: number
}

type CalendarInputState = ICalendarState & {
    selectedDateInput?: string
}

class CalendarInput extends Calendar<CalendarInputProps, CalendarInputState> {
    constructor(props: CalendarInputProps) {
        super(props)
    }

    handleToggleCalendar = () => {
        this.setState((prevState) => ({
            isCalendarVisible: !prevState.isCalendarVisible,
        }))
    }

    componentDidMount(): void {
        this.setState({
            selectedDateInput: this.getSelectedDate(this.state),
        })
    }

    componentDidUpdate(
        prevProps: Readonly<CalendarInputProps>,
        prevState: Readonly<CalendarInputState>,
        snapshot?: any,
    ): void {
        const newSelectedDate = this.getSelectedDate(this.state)

        if (prevState.selectedDateInput !== newSelectedDate) {
            this.setState({ selectedDateInput: newSelectedDate })
        }
    }

    render() {
        const {
            currentDate,
            placeHolder,
            placeHolderStyle,
            theme,
            leftIcon,
            popUpWidth,
            testID,
            ...rest
        } = this.props

        const { isCalendarVisible } = this.state

        return (
            <>
                <Pressable
                    onPress={this.handleToggleCalendar}
                    style={[styles.container]}
                >
                    {leftIcon}
                    <Text
                        style={[styles.textInputStyle, placeHolderStyle]}
                        testID={testID}
                    >
                        {(this.state.selectedDateInput || placeHolder) ??
                            'Select date'}
                    </Text>
                </Pressable>
                <OutsideClickHandler
                    onPress={() =>
                        this.setState({
                            isCalendarVisible: false,
                        })
                    }
                >
                    <View style={{ position: 'relative', zIndex: 1 }}>
                        <View
                            style={{
                                position: 'absolute',
                                width: popUpWidth || 300,
                            }}
                        >
                            {isCalendarVisible && (
                                <this.Calendar
                                    testID={testID + '-sub'}
                                    theme={{
                                        calendarBackground: 'transparent',
                                        selectedDayBackgroundColor:
                                            COLORS.COLOR_BLUE,
                                        selectedDayTextColor:
                                            COLORS.COLOR_HIGHLIGHTBLUE,
                                        textDayFontWeight: '400',
                                        textDayFontSize: 14,
                                        textSectionTitleColor:
                                            COLORS.COLOR_GRAY,
                                        todayTextColor: COLORS.COLOR_BLACK,
                                        dayTextColor: COLORS.COLOR_LIGHTBLACK,
                                        textDisabledColor:
                                            COLORS.COLOR_GRAY_PLACEHOLDER,
                                        monthTextColor: COLORS.COLOR_LIGHTBLACK,
                                        textMonthFontWeight: '700',
                                        textMonthFontSize: 14,
                                        ...theme,
                                    }}
                                    {...rest}
                                />
                            )}
                        </View>
                    </View>
                </OutsideClickHandler>
            </>
        )
    }
}

export default CalendarInput
