import React from 'react'
import { Image } from 'react-native'
/**
 * @deprecated Since version 1.1.9 Will be fixed in newer releases
 */
const SplineAreaChart = () => {
    return (
        <Image
            source={{ uri: '/spline_area_chart_.png' }}
            style={{ height: 250, width: 500 }}
        />
    )
}

export default SplineAreaChart
