import React from 'react'
import { Image } from 'react-native'
/**
 * @deprecated Since version 1.1.9 Will be fixed in newer releases
 */
const BarChart = (props: any) => {
    return (
        <Image
            source={{ uri: '/bar_chart_.png' }}
            style={{ height: 250, width: 250 }}
        />
    )
}

export default BarChart
