import React, { Children, useEffect } from 'react'
import { Text as TextComp } from 'react-native'
// import { useFonts } from 'expo-font';
/**
 * @deprecated Since version 1.1.4. Use Typography component instead.
 */
const Text = (props: any) => {
    // const [fontsLoaded] = useFonts({
    //     'Arial': require('../../assets/fonts/Arial.ttf'),
    //     'Arial': require('../../assets/fonts/Arial.ttf'),
    //     'Inter-Medium': require('../../assets/fonts/Inter-Medium.ttf'),
    // });
    return <TextComp {...props}>{props?.children}</TextComp>
}
export default Text
